import { autoinject, LogManager, PLATFORM } from 'aurelia-framework';
import { ProgramSurvey, surveyFields } from '../../../types/ProgramSurvey';
import { DatafiProAPI } from '../../../services/api/DatafiProAPI';
import { ensureNumber } from '../../../util/numbers/ensureNumber';
import { APIFile, DefaultActionData, Field, Option, Program, SurveyItemChoice, SurveyItemField, TSurveyType } from '@wsb_dev/datafi-shared/lib/types';
import { ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { AlertService } from '../../../services/util/Alert';
import { getExcelJson } from '../../../services/api/hooks/files/getExcelJson';
import { xformChoicesSchema, xFormSchema } from '../../../services/api/hooks/files/xform/xformSchema';
import { titleize } from '@wsb_dev/datafi-shared/lib/util/strings/titleize';
import { updateFields } from '../../../components/programs/program-survey-action-edit/updateFields';
import { getFields as getSurveyFields } from '@wsb_dev/datafi-shared/lib/util/surveys/getFields';
import { Router } from 'aurelia-router';
import { DirtyChecker } from '../../../types/DirtyChecker';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { DialogService } from 'aurelia-dialog';
import { SurveyFieldEdit } from '../survey-field-edit/survey-field-edit';
import { generatePath } from '@wsb_dev/datafi-shared/lib/util/files/generatePath';

PLATFORM.moduleName('./../survey-field-edit/survey-field-edit');

const logger = LogManager.getLogger('dfp:survey-editor');
export interface ISurveyTypeOption {
    value: TSurveyType;
    label: string;
}
@autoinject
export class SurveysEdit extends DirtyChecker {
    public dirtyProps: string[] = ['survey']
    programId: number;
    surveyId: number;
    survey: ProgramSurvey;
    program: Program;

    validationController: ValidationController;
    surveyOptions: Option[] = [];
    surveyOptionsLoading: boolean;

    constructor(
        private api: DatafiProAPI,
        private validationFactory: ValidationControllerFactory,
        private alerts: AlertService,
        private router: Router,
        private dialogService: DialogService,
    ) { super(); }

    activate(params, config) {
        this.surveyId = ensureNumber(params.surveyId);
        this.programId = ensureNumber(config.settings.programId);
        return this.updateSurvey();
    }

    async updateSurvey() {

        this.survey = await (this.surveyId ?
            this.api.programSurveys.get(this.surveyId).then((result) => this.survey = result as ProgramSurvey) :
            Promise.resolve(new ProgramSurvey({ program_id: this.programId, active: true, shareToPublic: false })));
        this.program = await this.api.programs.get(this.programId);
        this.validationController = this.validationFactory.createForCurrentScope();

        this.updateClones();
        return this.updateSurveys();
    }

    batchEditFields() {
        return this.dialogService.open({
            viewModel: SurveyFieldEdit,
            model: { model: this.survey.surveySchema },
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }
            this.survey.surveySchema = result.output;
            logger.debug('batch updated fields', this.program);
        });
    }

    async updateSurveys() {

        return this.api.files.find({
            query: {
                $path: generatePath({ name: 'form', programId: this.survey.program_id }),
                $limit: 500,
            },
        }).then((files: APIFile[]) => {
            this.surveyOptions = files.filter((file) => /\.xlsx?/.test(file.id))
                .map((f) => {
                    const fileParts = f.id.split('/');
                    const name = fileParts[fileParts.length - 1].split('.xml')[0];
                    return { value: f.id, label: titleize(name) };
                });
        });
    }

    /**
     * Downloads the list of survey fields configured and reconfigures existing fields
     * on top of the downloaded list. Its a way to automatically reload the existing
     * configuration with newly configured fields
     * @param surveyId
     * @returns
     */
    async refreshSurveyFields() {
        if (!this.survey.survey_id) {
            return;
        }

        let fields: Field[];

        const file = await this.api.files.get(this.survey.survey_id, { $url: false, $limit: 50 });
        const [formFields, formChoices] = await Promise.all([
            getExcelJson(file, { sheet: 1, schema: xFormSchema }) as Promise<SurveyItemField[]>,
            getExcelJson(file, { sheet: 2, schema: xformChoicesSchema }) as Promise<SurveyItemChoice[]>,
        ]);

        // remove fields that are just blank lines
        const nonEmptyFields = formFields
            .filter((field) => !!field.name || !!field.type?.replace(/ +/g, ''));

        // get array of fields converted from the survey spreadsheet
        const metadata = getSurveyFields(nonEmptyFields as SurveyItemField[], formChoices as SurveyItemChoice[]);
        fields = [
            ...surveyFields,
            {
                name: 'metadata', type: 'object', fields: metadata, visibility: {
                    list: false,
                    filter: false,
                    edit: true,
                },
            },
        ];

        // if fields are already configured, take the configuration from
        // existing fields and apply it on top
        // of the new survey fields so we don't lose the configuration
        fields = updateFields(fields, this.survey.surveySchema);

        logger.debug('program-survey: fields: ', fields);
        this.survey = new ProgramSurvey({
            ...this.survey,
            surveySchema: getFields(fields),
        });
    }

    updateProgramFolders() {
        const programFolderExists = this.program.fileFolders.find((folder) => folder.value === `surveys-${this.survey.id}`);

        if (!this.survey.addAttachments) {
            return;
        }

        if (this.survey.addAttachments && !programFolderExists) {
            this.program.fileFolders.push(
                { label: this.survey.title, value: `surveys-${this.survey.id}` },
            );
        }
        return this.api.programs.patch(this.program.id, {
            fileFolders: this.program.fileFolders,
        }).then((result) => {
            Object.assign(this.program, result);
            logger.debug('updated program', this.program);
        });
    }

    submit(finished?: boolean) {
        this.survey.survey_type = 'dfp-form';
        return this.validationController.validate()
            .then((result) => {
                if (result.valid) {
                    if (this.survey.id) {
                        return this.api.programSurveys.patch(this.survey.id, this.survey);
                    } else {
                        return this.api.programSurveys.create(this.survey);
                    }
                }
            })
            .then(async (result) => {
                this.alerts.create({
                    label: 'Survey updated',
                    level: 'success',
                    dismissable: true,
                });

                Object.assign(this.survey, result);
                this.updateClones();
                this.updateProgramFolders();

                if (!this.survey.id) {
                    this.router.navigateToRoute('surveys-edit', { surveyId: result.id });
                }
                if (finished) {
                    this.router.navigateToRoute('surveys-list');
                }
            }).catch((e) => {

                this.alerts.create({
                    label: e.message,
                    level: 'error',
                    dismissable: true,
                });
            });
    }

    addAction() {
        this.survey.actions = [
            ...this.survey.actions,
            {} as DefaultActionData,
        ];
    }

    removeAction(index: number) {
        this.survey.actions?.splice(index, 1);
    }

    cancel() {
        this.router.navigateToRoute('surveys-list');
    }

}
