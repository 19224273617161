import { EventAggregator } from 'aurelia-event-aggregator';
import { colorThemes } from '../../util/colorThemes/getColorTheme';
import { autoinject, bindable, LogManager } from 'aurelia-framework';
import { ChartConfiguration, TimeUnit } from 'chart.js';
import { StoreManager } from '../../services/util/StoreManager';
import { SimpleStore } from '../../services/util/SimpleStore';
import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { titleize } from '@wsb_dev/datafi-shared/lib/util/strings/titleize';
import { Stats } from './utils/chart-editor-utils';
import { IDashboardChartConfig, IDashboardItem, SummaryStat } from '../../types/Dashboards';
import { keys, groupBy, get, defaults } from 'lodash';
import { AlertService } from 'services/util/Alert';
const logger = LogManager.getLogger('dfp:chart-editor');

@autoinject
export class ChartEditor {
    @bindable editItem: IDashboardItem<IDashboardChartConfig>;
    currentEditItem: IDashboardItem<IDashboardChartConfig>;
    chartOptions: ChartConfiguration;
    charts: string[] = ['bar', 'pie'];
    // this specifies the date group and interval in seconds
    timeframes: string[] = ['day', 'week', 'month', 'quarter', 'year']
    timeInterval: TimeUnit = 'day';
    datasetTitle: string;
    fields: Field[] = [];
    ready: boolean;
    store: SimpleStore;
    ops: Stats[] = ['Minimum', 'Maximum', 'Count', 'Sum', 'Mean'];
    noneField: Field = { label: 'None', name: 'none' };
    rangeOptions = {
        mode: 'range', name: 'Date Range', altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d', disableMobile: true, defaultDate: [],
        onClose: (selectedDates: Date[], dateString: string): void => {
            this.editItem.config.dateRange = dateString;
            this.publishEditItem();
        },
    };
    selectedDate: string;
    storeLoading = false;
    chartThemes = colorThemes;
    @bindable hideLabelOptions: string[];
    @bindable hiddenLabel: string;

    constructor(
        private ea: EventAggregator,
        private element: Element,
        private stores: StoreManager,
        private alerts: AlertService,
    ) { }

    async activate(model: IDashboardItem): Promise<void> {
        this.ready = false;
        await this.loadStore(model.config.storeId, model.config);
        this.editItem = model;
        this.chartOptions = <ChartConfiguration>this.editItem.config?.chartOptions;
        this.datasetTitle = this.chartOptions?.data?.datasets[0]?.label !== undefined ? this.chartOptions.data?.datasets[0]?.label : 'New Dataset';
        this.ready = true;
    }

    setHideOptions() {
        const config = this.editItem.config;

        let hideOptions = keys(groupBy(this.store?.data, (item) => get(item, config.categoryField.path)));
        if (config.splitField) {
            const splitOptions = keys(groupBy(this.store?.data, (item) => get(item, config.splitField.path)));
            hideOptions.push(...splitOptions);
        }

        if (!hideOptions.length) return;

        hideOptions = hideOptions.filter((opt) => opt !== 'undefined');
        const currentOptionsString = this.hideLabelOptions.join('');
        const newOptionsString = hideOptions.join('');

        if (currentOptionsString !== newOptionsString) {
            config.hideAllLabels = false;
            config.hiddenLabels = [];
            this.hideLabelOptions = hideOptions;
        }
    }

    async updateFields(event?) {
        if (this.storeLoading) {
            return;
        }

        const config = this.editItem.config;

        defaults(config, {
            categoryField: this.noneField,
            splitField: this.noneField,
            statsFields: [{ field: this.noneField, stat: 'Count' }],
            datalabels: false,
            chartOptions: {
                type: 'bar',
                data: {
                    datasets: [],
                },
                plugins: [],
            },
        });

        if (this.hiddenLabel && !config.hiddenLabels?.includes(this.hiddenLabel)) {
            config.hiddenLabels = config.hiddenLabels || [];
            config.hiddenLabels.push(this.hiddenLabel);
        }
        this.hiddenLabel = '';

        // if we switch out of a date field, reset the date range
        if (config.categoryField?.type !== 'date') {
            config.dateRange = '';
        }

        // only bar charts get the splitfield
        if (config.chartOptions.type !== 'bar') {
            config.splitField = this.noneField;
        }

        config.title = this.generateChartTitle();

        this.setHideOptions();

        if (event === 'store') {
            await this.loadStore(config.storeId, config);
        }

        this.publishEditItem('update fields');
    }

    // little function for generating the chart title
    generateChartTitle(): string | string[] {
        const config = this.editItem.config;

        if (config.customTitle) {
            return config.title;
        }

        let title = '';

        // use label if it exists
        const getFieldLabel = (field) => field?.label !== undefined ? titleize(field.label) : titleize(field.name);
        const categoryField = getFieldLabel(config.categoryField);
        const splitField = getFieldLabel(config.splitField);

        const statsFields = (config.statsFields || this.legacyStatsFields(config)).filter((sf) => sf.field.name !== 'none');
        const statsField = statsFields.map((sf, i) => {
            const sfLabel = sf.field.label !== undefined ?
                titleize(sf.field.label) :
                titleize(sf.field.name);
            const grammar = i !== 0 ? i === statsFields.length - 1 ? ' and ' : ' ' : '';

            return `${grammar}${sf.stat} of ${sfLabel}`;
        });

        title = `${statsField.length ? statsField : 'None'} vs. ${categoryField}`;

        if (config.splitField.name !== 'none') {
            title += ` Split by ${splitField}`;
        }

        if (config.dateRange !== '') {
            // break this up because it gets long
            return [title, `for Period ${config.dateRange}`];
        } else {
            return title;
        }
    }

    clearDates(): void {
        this.selectedDate = '';
        this.editItem.config.dateRange = '';
        this.updateFields();
    }

    publishEditItem(event?) {
        if (this.editItem !== undefined) {
            logger.debug('published new chart', event, this.editItem);
            this.ea.publish('update-chart', this.editItem);
        }
    }

    // only called on activation or when the store is changed
    async loadStore(storeId: number, config) {
        const prevSurvey = this.store?.defaultQuery.survey_id;

        const dataStore = this.stores.getStore(config.storeId) as SimpleStore;
        if (dataStore) {
            this.storeLoading = true;
            await dataStore.refresh().then((dataStore) => {
                // remove the metadata field after flattening
                this.fields = dataStore.fields
                    .filter((field) => { return field.name !== 'metadata'; });

                // go ahead and add in a none option
                this.fields.unshift(this.noneField);

                // set the store
                this.store = dataStore;

                // find existing fields by index
                config.categoryField = this.fields.find((f) => f.name === config.categoryField?.name) || this.noneField;

                //handle old statsfields
                const statsFields = (config.statsFields || this.legacyStatsFields(config)).filter((sf) => sf.field.name !== 'none');
                config.statsFields = statsFields.length ? statsFields : [{ field: { label: 'Id', name: 'id', path: 'id' }, stat: 'Count' }];
                delete config.statsField; delete config.stat;

                config.chartTheme = this.chartThemes.find((co) => co.label === config.chartTheme?.label) || this.chartThemes[0];

                // ignore split for pie and doughnut charts
                if (config.chartOptions.type !== 'bar') {
                    config.splitField = this.noneField;
                } else {
                    config.splitField = this.fields.find((f) => f.name === config.splitField?.name) || this.noneField;
                }

                config.datalabels = config.datalabels !== undefined ? config.datalabels : false;
                config.stacked = config.stacked !== undefined ? config.stacked : false;
                config.doughnut = config.doughnut !== undefined ? config.doughnut : false;
                config.timeInterval = config.timeInterval !== undefined ? config.timeInterval : 'day';
                config.dateRange = config.dateRange !== undefined ? config.dateRange : '';

                const dr = config.dateRange.split(' ');
                dr.length > 1 ? this.rangeOptions.defaultDate = [dr[0], dr[2]] : [];

                this.hideLabelOptions = keys(groupBy(this.store?.data, (item) => get(item, config.categoryField.path))) || [];
                if (prevSurvey && prevSurvey !== this.store?.defaultQuery.survey_id) {
                    config.hideAllLabels = false;
                    config.hiddenLabels = [];
                    this.hideLabelOptions = [];
                }

                this.storeLoading = false;
            }).catch((err) => {
                this.storeLoading = false;
                this.alerts.create({
                    level: 'error',
                    label: `Error fetching DataStore: ${dataStore.name} (${err.message})`,
                    dismissable: true,
                });
            });
        } else { this.fields.unshift(this.noneField); }
    }

    //Functions for handling legacy chart properties
    legacyStatsFields(config: IDashboardChartConfig | any): [{ field: Field, stat: SummaryStat }] {
        return config.statsField?.name !== 'none' ?
            [{ field: config.statsField, stat: config.stat as SummaryStat }] :
            [{ field: { label: 'Id', name: 'id', path: 'id' }, stat: 'Count' }];
    }
}
