import { ProgramConfig } from '@wsb_dev/datafi-shared/lib/types';
import { DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { ConfirmDialog } from '../../components/confirm-dialog/confirm-dialog';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { AlertService } from '../../services/util/Alert';
import { StoreManager } from '../../services/util/StoreManager';
import { ensureNumber } from '../../util/numbers/ensureNumber';

export interface DataStoreDetails {
    id: number;
    name: string;
    dataType: string;
    loadOffline: boolean;
    filter: boolean;
    survey?: string;
    fieldSelection?: string;
    geometry?: boolean;
}

@autoinject
export class ConfigStoreManager {

    programId: number;
    configs: ProgramConfig[];
    dataStoreDetails: DataStoreDetails[] = [];

    constructor(
        private api: DatafiProAPI,
        private dialogs: DialogService,
        private alerts: AlertService,
        private stores: StoreManager,
    ) { }

    async activate(params, config) {
        this.programId = ensureNumber(config.settings.programId);
        this.stores.activate(this.programId);
        const programSurveys = await this.api.programSurveys.find({
            query: {
                program_id: this.programId,
                $select: ['id', 'title'],
            },
        }).then((res: any) => res.data);

        const serviceTypeMapping = {
            'api/v1/form-submissions': 'Survey',
            'api/v1/projects-files': 'Project Files',
            'api/v1/projects': 'Projects',
            'api/v1/programs-users-roles': 'User Location',
        };

        this.stores?.stores.map(async (store) => {
            const surveyTitle = programSurveys.find((sv) => sv.id === store.query?.survey_id)?.title;
            const fieldSelection = store.query?.$modify?.fieldSelection?.length;
            const geometry = store?.query?.$modify?.toGeoJSON;
            const loadOffline = store.query?.$client;

            this.dataStoreDetails.push({
                id: store.id,
                name: store.name,
                dataType: serviceTypeMapping[store.service.path] || store.service.path,
                survey: surveyTitle ? `: ${surveyTitle}` : '',
                fieldSelection: fieldSelection ? `${fieldSelection} of 15 selected` : 'All',
                geometry: geometry ?  true : false,
                loadOffline: loadOffline ? false : true,
                filter: store.query?.metadata || store.query?.$modify?.checkboxQuery ? true : false,
            });
        });
    }

    delete(id: number) {
        return this.dialogs.open({
            viewModel: ConfirmDialog,
            model: {
                message: 'Are you sure you want to delete this data store? Existing dashboards may no longer function correctly.',
                title: 'Confirm Delete',
            } as Partial<ConfirmDialog>,
        })
            .whenClosed((result) => {
                if (result.wasCancelled) {
                    return;
                }
                return this.api.programConfigs.remove(id)
                    .then(() => {
                        this.alerts.create({
                            label: 'Data store removed',
                            level: 'success',
                            dismissable: true,
                        });
                        return this.stores.updateConfigs();
                    });
            })
            .catch((e) => {
                this.alerts.create({
                    label: `Error: ${e.message}`,
                    level: 'error',
                    dismissable: true,
                });
            });
    }

}
