import { userHasPermission } from '@wsb_dev/datafi-shared/lib/util/users/userHasPermission';
import { ValidatedUser } from 'types/Users';
import { Program } from 'types/Program';

export function hasOrgPermission(user: ValidatedUser, program: Program): boolean {
    if (!user) {
        return;
    }
    const orgsMatch = program.managementOrganizationId ?
        user.organizationId === program.managementOrganizationId :
        true;
    const isAdmin = userHasPermission(user, '*', '*');
    const hasPermission = userHasPermission(user, 'api/v1/projects', 'patch', program.id);

    return (orgsMatch && hasPermission) || isAdmin;
}
