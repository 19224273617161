import { bindable, autoinject, LogManager, PLATFORM } from 'aurelia-framework';
import { ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { Program } from '../../../types/Program';
import { DialogService } from 'aurelia-dialog';
import { FilterDialog } from '../../../components/filter-dialog/filter-dialog';
import { Filter } from '@wsb_dev/datafi-shared/lib/types';
import { FilterActivateParams } from '@wsb_dev/datafi-shared/lib/types/Filter';
import { filtersToQuery } from '@wsb_dev/datafi-shared/lib/util/fields/filtersToQuery';
import { queryToFilter } from '@wsb_dev/datafi-shared/lib/util/fields/queryToFilter';
import { projectFilters } from '../../../types/Project';
import { FileFolder } from '@wsb_dev/datafi-shared/lib/types/FileFolder';
import { SurveyFieldEdit } from '../survey-field-edit/survey-field-edit';

PLATFORM.moduleName('./../survey-field-edit/survey-field-edit');
const logger = LogManager.getLogger('dfp:project-editor');

@autoinject
export class ProjectEdit {
    validationController: ValidationController;
    @bindable program: Program;
    @bindable sort: number;
    @bindable programId: number;
    @bindable currentProjectFilters: Filter[];
    @bindable fileFolders: FileFolder[];

    constructor(
        private validationFactory: ValidationControllerFactory,
        private dialogService: DialogService,
    ) {
        this.validationController = this.validationFactory.createForCurrentScope();
    }

    async activate(params: any, config) {
        this.sort = -1;
        this.programId = parseInt(params.id, 10);
        this.program = config.settings.program;

        if (!this.program.projectQuery) {
            this.program.projectQuery = {};
        }

        if (!this.program.projectSchema) {
            this.program.projectSchema = [];
        }

        if (!this.program.fileFolders) {
            this.program.fileFolders = [];
        }

        if (!this.currentProjectFilters) {
            this.currentProjectFilters = [
                ...projectFilters as Filter[],
                { name: 'metadata', label: 'More Filters', type: 'object', fields: [...this.program.projectSchema] },
            ];
        }

        this.currentProjectFilters = queryToFilter(this.currentProjectFilters, [], this.program.projectQuery);
    }

    buildQuery(): void {
        this.dialogService.open({
            viewModel: FilterDialog,
            model: {
                filterProperties: this.currentProjectFilters,
            } as FilterActivateParams,
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            } else {
                const query = filtersToQuery(result.output);
                this.program.projectQuery = {
                    ...this.program.projectQuery,
                    ...query,
                };
            }
        });
    }

    batchEditFields() {
        return this.dialogService.open({
            viewModel: SurveyFieldEdit,
            model: {model: this.program.projectSchema },
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }
            this.program.projectSchema = result.output;
            logger.debug('batch updated fields', this.program);
        });
    }
}
