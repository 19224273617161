import { AlertService } from './services/util/Alert';
import { AppRouter, RouterConfiguration, RouterEvent } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { LogManager, autoinject } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { hasOrgPermission } from 'util/orgs/hasOrgPermission';

import omit from 'lodash.omit';

import { DefaultActionData } from '@wsb_dev/datafi-shared/lib/types/ActionTypes';
import { RecentPrograms } from './services/util/RecentPrograms';
import { Events } from '@wsb_dev/datafi-shared/lib/types/Events';

import './styles/main.scss';
import { onIframeEventMessageHandler } from './util/events/onIframeEventMessageHandler';
import { DialogService } from 'aurelia-dialog';
import { ConfirmDialog, ConfirmDialogActivate } from './components/confirm-dialog/confirm-dialog';
import { DatafiProAPI } from './services/api/DatafiProAPI';
import { AuthorizeStep } from './services/util/AuthorizeStep';
import { FeatureFlagsService } from './services/util/FeatureFlags';
import { subscribe } from './util/decorators/subscribe';
import { Cache } from './services/util/FileCache';

const log = LogManager.getLogger('dfp:app');

export interface IFramePage {
    title: string;
    icon: string;
    src: string;
    saved: boolean;
}

@subscribe({
    events: [
        {eventEmitter: 'flags', event: 'update', fn: 'handleFeatureFlags'},
    ],
})
@autoinject
export class App {
    title = 'Datafi Pro';
    router: AppRouter;

    iframeSrc = 'about:blank';
    iframeActive: boolean;

    iframePages: IFramePage[] = [];
    activeIframe: IFramePage;
    onUnloadRegistered = false;
    hasOrgPermission = hasOrgPermission

    private events: Subscription[] = [];

    constructor(
        private ea: EventAggregator,
        private alerts: AlertService,
        private dialogService: DialogService,
        private api: DatafiProAPI,
        private recentPrograms: RecentPrograms, // used in view
        private flags: FeatureFlagsService,
    ) {

        // event subscribing
        this.events.push(ea.subscribe(Events.APP_ACTION_PENDING, (data: DefaultActionData) => {
            if (data.loadingMessage) {
                this.alerts.create({ label: data.loadingMessage, dismissable: true });
            }
        }));

        this.events.push(ea.subscribe(Events.APP_ACTION_SUCCESS, (data: DefaultActionData) => {
            if (data.completedMessage) {
                this.alerts.create({ label: data.completedMessage, dismissable: true });
            }
        }));

        this.events.push(ea.subscribe(Events.APP_ACTION_FAIL, (data: DefaultActionData) => {
            if (data.errorMessage || data.showError) {
                const message = `${data.errorMessage}. ${data.showError ? data.error : ''}`;
                this.alerts.create({
                    label: message,
                    level: 'error',
                    timeout: 8000,
                    dismissable: true,
                });
            }
            this.handleError(omit(data, ['auth']) as DefaultActionData);
        }));

        // window global error reporter
        window.onerror = (error) => this.handleError({
            error: (error as unknown as Error),
            id: 'global',
        });

        window.addEventListener(
            'message',
            onIframeEventMessageHandler.bind(this),
            false,
        );

        this.events.push(ea.subscribe('iframe', (iframe: IFramePage) => {
            this.iframePages.push(iframe);
            this.iframePages.sort((a, b) => {
                return a.saved === b.saved ? 0 :
                    a.saved && !b.saved ? 1 : -1;
            });
            this.activeIframe = iframe;
            this.validateUnload();
        }));
        this.events.push(this.ea.subscribe(RouterEvent.Complete, () => this.activeIframe = null));
    }

    bind() {
        this.api.auth.get();
        this.api.auth.me?.getProfile();
        return;
    }

    unbind() {
        this.events.forEach((ev) => ev.dispose());
    }

    handleFeatureFlags(flags:FeatureFlagsService){
        const cacheEnabled = flags.browser_image_cache;
        log.info(`cache status: ${cacheEnabled ? 'enabled' : 'disabled'}`);
        if(cacheEnabled){
            Cache.enable();
        } else {
            Cache.disable();
        }
    }

    handleError(data: DefaultActionData) {
        this.api.events.create({
            user_id: this.api.auth.me?.id,
            service: 'frontend',
            method: data.id,
            type: 'error',
            message: data.error.message,
            data: JSON.parse(JSON.stringify({ stack: data.error.stack, data: omit(data, ['auth', 'viewModel']) })),
        }).catch((e) => log.debug(e));
    }

    configureRouter(config: RouterConfiguration, router: AppRouter) {
        this.router = router;

        config.title = 'WSB Datafi Pro';
        config.options.pushState = true;
        config.options.root = '/';

        config.map([
            { name: 'home', route: ['', 'home'], moduleId: PLATFORM.moduleName('pages/home'), title: 'Home', settings: { auth: true } },
            { name: 'not-found', route: ['not-found'], moduleId: PLATFORM.moduleName('pages/not-found'), title: '404 Not Found' },
            { name: 'program/config', route: ['program/config/:id'], moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/dashboard-program-edit'), title: 'Edit Program', settings: { auth: true } },
            { name: 'program/dashboard-manager', route: ['program/dashboard-manager/:id'], moduleId: PLATFORM.moduleName('pages/dashboard-manager/dashboard-manager'), title: 'Dashboard Manager', settings: { auth: true } },
            { name: 'login', route: 'login', moduleId: PLATFORM.moduleName('pages/login'), title: 'Login' },
            { name: 'projects', route: ['programs/:programId/projects'], moduleId: PLATFORM.moduleName('pages/projects'), title: 'Inspection Map', settings: { auth: true } },
            { name: 'admin', route: ['admin/:page?'], moduleId: PLATFORM.moduleName('pages/admin/admin'), title: 'Administration', settings: { auth: true } },
            { name: 'assets', route: 'assets', moduleId: PLATFORM.moduleName('components/assets/assets'), title: 'Collect New Asset', nav: true, settings: { auth: true } },
            { name: 'dashboard-share', route: ['program/:programId/dashboard/:configId'], moduleId: PLATFORM.moduleName('pages/dashboard-shareable/dashboard-shareable'), settings: { auth: true } },
            { name: 'dfp-form', route: ['dfp-form/program/:programId/survey/:surveyId/:metadata?'], moduleId: PLATFORM.moduleName('pages/dfp-form'), title: 'Form', settings: { auth: true } },
        ]);

        config.fallbackRoute('not-found');
        config.mapUnknownRoutes('pages/not-found');
        config.addAuthorizeStep(AuthorizeStep);
    }

    logout() {
        return new Promise<void>((resolve, reject) => {
            if (this.iframePages.length) {

                this.dialogService.open({
                    viewModel: ConfirmDialog,
                    model: {
                        title: 'Logout?',
                        message: 'Are you sure you want to logout? Unsubmitted surveys data may be lost. ',
                    } as ConfirmDialogActivate,
                }).whenClosed((result) => {
                    if (result.wasCancelled) {
                        reject(new Error('cancelled'));
                    } else {
                        resolve();
                    }
                });
            } else {
                resolve();
            }
        }).then(() => {
            this.api.auth.logout()
                .then(() => this.router.navigateToRoute('home'))
                .catch((e) => log.error(e))
                .then(() => {
                    this.recentPrograms.clear();
                    this.iframePages = [];
                    this.activeIframe = null;
                    this.router.navigate('/');
                    this.validateUnload();
                    this.alerts.create({ label: 'Logout successful', dismissable: true });
                });
        }).catch((e) => log.debug(e));
    }

    removeIframe(iframe: IFramePage) {
        const index = this.iframePages.indexOf(iframe);
        this.iframePages.splice(index, 1);
        if (this.activeIframe === iframe) {
            this.activeIframe = null;
        }

        this.validateUnload();

    }

    onBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = `
        You have active forms open, are you sure you want to leave? 
        You may lose any unsaved work.`;
        return event.returnValue;
    }

    validateUnload() {
        if (this.iframePages.length) {
            if (!this.onUnloadRegistered) {
                log.debug('app: setting beforeunload handler');
                window.addEventListener('beforeunload', this.onBeforeUnload);
                this.onUnloadRegistered = true;
            }
        } else {
            if (!this.onUnloadRegistered) {
                return;
            }
            log.debug('app: removing beforeunload handler');
            window.removeEventListener('beforeunload', this.onBeforeUnload);
            this.onUnloadRegistered = false;
        }
    }
}
