import { bindable, autoinject } from 'aurelia-framework';
import { IDashboardChartConfig } from 'types/Dashboards';
import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { Stats } from '../../utils/chart-editor-utils';
import { createCustomEvent } from '../../../../util/events/createCustomEvent';

@autoinject
export class PieChartEditor {
    @bindable config: IDashboardChartConfig;
    @bindable fields: Field[];

    ops: Stats[] = ['Minimum', 'Maximum', 'Count', 'Sum', 'Mean'];

    constructor(
        private element: Element,
    ) { }

    addValue(): void {
        this.config.statsFields.push({
            field: { label: 'Id', name: 'id', path: 'id' },
            stat: 'Count',
        });
        if (this.config.categoryField.name !== 'none') {
            this.config.categoryField = {label: 'None', name: 'none'};
        }
        this.element.dispatchEvent(createCustomEvent('change', 'statsFields', true));
    }

    removeValue(index: number): void {
        this.config.statsFields.splice(index, 1);
        this.element.dispatchEvent(createCustomEvent('change', 'statsFields', true));
    }
}
