import { LogManager } from 'aurelia-framework';
import {get, set, mapValues, groupBy} from 'lodash';
import { Field } from '@wsb_dev/datafi-shared/lib/types';
const logger = LogManager.getLogger('dfp:chart-editor-utils');

// helper functions for data wrangling with chartjs
export type Stats = 'Minimum' | 'Maximum' | 'Count' | 'Sum' | 'Mean'

/**
 * recursive way to return all values based on an input search key, works on nested objects
 * @param inObject in object we need the values from
 * @param searchKey the string of the key we are looking to return values for
 * @param results an array of values that may be added to recursively depending on whether or not nested objects are encountered
 * @returns final array of values
 */
export function keySearch(inObject: any, searchKey: string, results: Array<any> = []): Array<any> {

    // dont do anything for none field
    if (searchKey === 'none') {
        return [];
    }

    const res = results;
    // loop through each key
    Object.keys(inObject).forEach((key) => {
        // get the value
        const val = inObject[key];
        // if the key matches our search key and the value isn't an object, add it to the results
        if (key === searchKey && typeof val !== 'object') {
            res.push(val);
            // otherwise call it again
        } else if (val && typeof val === 'object' && !key.includes('__')) {
            keySearch(val, searchKey, res);
        }
    });
    return res;
}

/**
 * this is the main function for formatting the data for ChartJS.
 * @param submissionData an array of form submission data to get data from
 * @param categoryField the category field for the chart. Can be a 'none' field
 * @param statsFields an array of stats fields to generate data sets for
 * @param splitField the split field for the chart, if it exists
 * @returns
 */
export function getLabelsAndData(submissionData: any[], categoryField: Field, statsFields: any[], splitField?: Field): {labels: string[], data: any[]} {
    const categoryDate = categoryField.type === 'date' ? true : false;
    const splitDate = splitField?.type === 'date' ? true : false;

    // get the category grouping and labels
    const categoryGrouping = mapValues(groupBy(submissionData, (item) => get(item, categoryField.path)));
    const categoryLabels = Object.keys(categoryGrouping);

    // get the split grouping and labels
    const splitGrouping = mapValues(groupBy(submissionData, (item) => get(item, splitField?.path)));
    const splitLabels = Object.keys(splitGrouping);

    // let's remove the undefined data from both cause it'll cause problems during calculations
    const firstUndefinedIndex = categoryLabels.indexOf('undefined');
    if (firstUndefinedIndex !== -1) categoryLabels.splice(firstUndefinedIndex, 1);
    const secondUndefinedIndex = splitLabels.indexOf('undefined');
    if (secondUndefinedIndex !== -1) splitLabels.splice(secondUndefinedIndex, 1);

    // if the category field is a date and there's a split field, create a dataset for each split field answer.
    if (categoryDate && splitField?.name !== 'none') {
        //data sets will be a for each of the the split labels, not statsfield.
        const datasets = splitLabels.map((sl) => {
            const subGrouping = mapValues(groupBy(splitGrouping[sl], (item) => get(item, categoryField.path)));
            const dateLabels = Object.keys(subGrouping);
            const temp = dateLabels.map((dl) => {
                //only use the first stat field in this situation
                const stat = statsFields[0];
                const statByLabel = keySearch(subGrouping[dl], stat.field.name);
                if (!statByLabel.every((item) => typeof item === 'number')) {
                    parseStatInts(statByLabel);
                }
                return getStats(statByLabel, stat.stat);
            });
            return {
                label: sl.replace(/_/g, ' '),
                data: temp,
            };
        });
        return {labels: categoryLabels.map((dl) => dl.split('T')[0] ), data: datasets};
    }

    // sort the data by category field and split field
    const sorted = [];
    categoryLabels.forEach((cl) => {
        if (splitLabels.length) {
            const subGrouping = mapValues(groupBy(categoryGrouping[cl], (item) => get(item, splitField.path)));
            splitLabels.forEach((sl) => {
                sorted.push({
                    categoryLabel: categoryDate ? cl.split('T')[0] : cl,
                    splitLabel: splitDate ? sl.split('T')[0] : sl,
                    data: subGrouping[sl] || [],
                });
            });
        }
        else {
            sorted.push({
                categoryLabel: categoryDate ? cl.split('T')[0] : cl,
                data: categoryGrouping[cl] || [],
            });
        }
    });

    // use the sorted array to create the labels data for each data set.
    const labels = sorted.map((obj) => `${obj.categoryLabel}${obj.splitLabel ? ('|delimiter|' + obj.splitLabel) : '' }`);
    // create a dataset for each stat field
    const datasets = statsFields.map((sf) => {
        const temp = sorted.map((obj) => {
            const statByLabel = keySearch(obj.data, sf.field.name);
            // make sure that we have numbers, otherwise try parsing it
            if (!statByLabel.every((item) => typeof item === 'number')) {
                parseStatInts(statByLabel);
            }
            return getStats(statByLabel, sf.stat);
        });
        return {
            label: `${sf.stat} of ${sf.field.label}`,
            data: temp,
        };
    });

    return {labels: labels, data: datasets};
}

/**
 * Function for generating simple statistics based on an input array
 * @param statByLabel array of numbers
 * @param statistic what statistic to calculate
 * @returns the calculated statistic
 */
export function getStats(statByLabel: Array<number>, statistic: string): number {
    let val = undefined;
    if (statByLabel.length > 0) {
        switch (statistic) {
        case 'Minimum' :
            val = Math.min(...statByLabel);
            break;
        case 'Maximum' :
            val =  Math.max(...statByLabel);
            break;
        case 'Sum' :
            val =  statByLabel.reduce((a, b) => a + b);
            break;
        case 'Mean' :
            val =  statByLabel.reduce((a, b) => a + b) / statByLabel.length;
            break;
        case 'Count' :
            val =  statByLabel.length;
            break;
        default :
            val = [];
        }
    } else {
        val = undefined;
    }
    return val;
}

/**
 * This function handles form submission data for a chart's date interval and set date range.
 * @param data an array of form submission data for a chart
 * @param path the path of the chart's selected date category field
 * @param timeInterval the time interval for the chart (day | week | month | quarter | year)
 * @param dateRange the date range for the chart, can be empty. 'YYYY-MM-DD to YYYY-MM-DD'
 * @returns an array of form submission data with filtered and altered date strings
 */
export function groupAndFilterDates(data: any[], path: string, timeInterval: string, dateRange ?: string) {
    if (!data) return [];

    const start = dateRange ? new Date(dateRange.split(' ')[0]) : undefined;
    const end = dateRange ? new Date(dateRange.split(' ')[2]) : undefined;

    return data.filter((datum) => {
        const oldDate = new Date(get(datum, path));
        const inRange = oldDate >= start && oldDate <= end || !dateRange;
        if (!isNaN(oldDate.getTime()) && inRange) {
            switch (timeInterval) {
            case 'day': {
                oldDate.setUTCHours(0, 0, 0, 0);
                break;
            }
            case 'week': {
                oldDate.setUTCDate(oldDate.getUTCDate() - oldDate.getUTCDay());
                oldDate.setUTCHours(0, 0, 0, 0);
                break;
            }
            case 'month': {
                oldDate.setUTCHours(0, 0, 0, 0);
                oldDate.setUTCDate(1);
                break;
            }
            case 'quarter': {
                const month = oldDate.getUTCMonth();
                const quarterMonth = month > 8 ? 9 : month > 5 ? 6 : month > 2 ? 3 : 0;
                oldDate.setUTCMonth(quarterMonth);
                oldDate.setUTCHours(0, 0, 0, 0);
                oldDate.setUTCDate(1);
                break;
            }
            case 'year': {
                oldDate.setUTCMonth(0);
                oldDate.setUTCHours(0, 0, 0, 0);
                oldDate.setUTCDate(1);
                break;
            }
            }
            set(datum, path, oldDate.toISOString());
            return datum;
        }
    });
}

export function parseStatInts(statByLabel: (number | string)[]): void {
    statByLabel.forEach((item) => {
        if (typeof item === 'string') {
            const newVal = parseInt(item);
            const index = statByLabel.indexOf(item);
            statByLabel[index] = newVal;
        }
    });
}
