import { bindable, autoinject } from 'aurelia-framework';
import { IDashboardChartConfig } from 'types/Dashboards';
import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { Stats } from '../../utils/chart-editor-utils';
import { createCustomEvent } from '../../../../util/events/createCustomEvent';

@autoinject
export class BarChartEditor {
    @bindable config: IDashboardChartConfig;
    @bindable fields: Field[];
    @bindable labelOptions: string;
    @bindable selectedLabel: string;

    ops: Stats[] = ['Minimum', 'Maximum', 'Count', 'Sum', 'Mean'];

    constructor(
        private element: Element,
    ) { }

    async showLabel(detail: { isComplete: boolean; chipIndex: number }): Promise<void> {
        if (detail.isComplete) {
            this.config.hiddenLabels.splice(detail.chipIndex, 1);
            this.triggerChangeEvent('remove hidden label');
        }
    }

    addValue(): void {
        this.config.statsFields.push({
            field: { label: 'Id', name: 'id', path: 'id' },
            stat: 'Count',
        });
        this.triggerChangeEvent('add stat field');
    }

    removeValue(index): void {
        this.config.statsFields.splice(index, 1);
        this.triggerChangeEvent('remove stat field');
    }

    triggerChangeEvent(eventData): void {
        this.element.dispatchEvent(createCustomEvent('change', eventData, true));
    }
}
