import { PLATFORM } from 'aurelia-pal';
import { Router, RouterConfiguration, Redirect, activationStrategy, RoutableComponentDetermineActivationStrategy } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Program } from '../../types/Program';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { ensureNumber } from '../../util/numbers/ensureNumber';
import { ProgramConfig } from '@wsb_dev/datafi-shared/lib/types';
import { Paginated } from '@feathersjs/feathers';
import { ValidatedProgramConfig } from '../../types/ProgramConfig';
import { StoreManager } from '../../services/util/StoreManager';
import { subscribe } from '../../util/decorators/subscribe';
import { EventAggregatorWrapper } from '../../util/events/eventAggregatorWrapper';
import { IDashboardItem } from '../../types/Dashboards';
import { ProgramActivity, RecentPrograms } from 'services/util/RecentPrograms';
import { userHasPermission } from '@wsb_dev/datafi-shared/lib/util/users/userHasPermission';
import { hasOrgPermission } from '../../util/orgs/hasOrgPermission';

@subscribe({
    events: [
        { eventEmitter: 'eaw', event: 'new-dashboard', fn: 'onDashboardCreated' },
    ],
})

@autoinject
export class DashboardManager implements RoutableComponentDetermineActivationStrategy {
    isMobile: boolean;
    programId: number;
    program: Program;
    dashboards: ProgramConfig[];
    mediaQuery: MediaQueryList;
    router: Router;

    constructor(
        private api: DatafiProAPI,
        private stores: StoreManager,
        private eaw: EventAggregatorWrapper,
        private recentPrograms: RecentPrograms,
    ) {
        this.eaw = eaw;
        this.mediaQuery = window.matchMedia('(max-width: 600px)');
        this.handleMediaQuery(this.mediaQuery);

        this.mediaQuery.addEventListener('change', this.handleMediaQuery);
    }

    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    handleMediaQuery = (evt) => {
        return evt.matches ? this.isMobile = true : this.isMobile = false;
    }

    async configureRouter(config: RouterConfiguration, router: Router, params) {
        const programId = ensureNumber(params.id);
        await this.fetchProgram(programId);
        const settings = { program: this.program, programId: this.programId };

        config.map([
            { name: 'data-stores', route: ['', 'data-stores'], moduleId: PLATFORM.moduleName('../../components/config-store-manager/config-store-manager'), settings },
            { name: 'data-stores-edit', route: ['data-stores/:configId'], moduleId: PLATFORM.moduleName('pages/dashboard-program-edit/routes/configs-edit'), settings },
            { name: 'dashboards', route: 'dashboards/:dashboardId', moduleId: PLATFORM.moduleName('pages/dashboard-manager/routes/dashboards'), settings },
        ]);

        config.fallbackRoute('home');
        this.router = router;
    }

    async canActivate(params) {
        await this.fetchProgram(ensureNumber(params.id));

        if (!hasOrgPermission(this.api.auth.me, {id: this.programId, managementOrganizationId: this.program.managementOrganizationId} as Program)) return new Redirect('/not-found');
    }

    async activate(params) {
        if (!this.program) {
            return;
        }
        await this.fetchProgram(ensureNumber(params.id));
        if (this.programId) {this.recentPrograms.manageActivity({id: this.programId, title: this.program.title, managementOrganizationId: this.program.managementOrganizationId} as ProgramActivity);}
        this.program.getThumbnail();
        return this.fetchDashboards();
    }

    async fetchProgram(programid: number){
        this.programId = programid;
        this.program = await this.api.programs.get(this.programId)
            .then((result) => new Program(result, this.api.files))
            .catch((e) => undefined);
    }

    fetchDashboards() {
        return Promise.all([
            this.api.programConfigs.find({
                query: { program_id: this.programId, type: 'dashboard' },
            }).then((result: Paginated<ProgramConfig>) => {
                const orgId = this.program?.managementOrganizationId;

                if (orgId) {
                    const matchingOrg = this.api.auth.me.organizationId === orgId;
                    const isAdmin = userHasPermission(this.api.auth.me, '*', '*');
                    if (!matchingOrg && !isAdmin) {
                        return;
                    }
                }

                this.dashboards = result.data.map((config) => new ValidatedProgramConfig(config));
            }),
            this.stores.activate(this.programId),
        ]);
    }

    onDashboardCreated = (dashboard: IDashboardItem) => {
        if (dashboard) {
            this.fetchDashboards();
        }
    }
}
