import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject
export class FieldListSearch {
    @bindable value: Field;
    @bindable selectedField: Field;
    @bindable options: Field[];
    @bindable currentOptions: Field[];
    @bindable label: string;
    @bindable outlined: boolean;

    attached() {
        this.currentOptions = this.options;
        this.selectedField = this.options.find((opt) => this.value?.name === opt.name);
    }

    selectedFieldChanged(field) {
        if (field) {
            this.value = field;
        }
    }

    optionsChanged(options) {
        if (JSON.stringify(options) !== JSON.stringify(this.currentOptions) || !this.currentOptions) {
            this.currentOptions = options;
        }
    }
}
