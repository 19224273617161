import { Paginated } from '@feathersjs/feathers';
import { AssetGeometryType, Field, Option, ProgramConfig } from '@wsb_dev/datafi-shared/lib/types';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { flattenFields } from '@wsb_dev/datafi-shared/lib/util/surveys/flattenFields';
import { autoinject } from 'aurelia-dependency-injection';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { programUserRoleFields } from '../../types/ProgramUserRole';
import { projectFilters } from '../../types/Project';
import { ensureNumber } from '../../util/numbers/ensureNumber';
import { SimpleStore, TStoreMode } from './SimpleStore';
import { AlertService } from '../../services/util/Alert';
import { LogManager } from 'aurelia-framework';
const log = LogManager.getLogger('dfp:store');

export interface IStoreConfig {
    service: string;
    query: Record<string, any>;
    mode?: TStoreMode;
    idField?: string;
    name?: string;
    expiration?: number;
    geometryType?: AssetGeometryType;
}

@autoinject
export class StoreManager {
    stores: SimpleStore[] = [];
    storeOptions: Option[] = [];
    programId: number;

    constructor(
        private api: DatafiProAPI,
        private alerts: AlertService,
    ) { }

    activate(programId: number) {
        this.programId = programId;
        return this.updateConfigs();
    }

    deactivate() {
        this.stores.forEach((store) => store.destroy());
    }

    async updateConfigs() {
        const result = await this.api.programConfigs.find({
            query: { program_id: this.programId, type: 'data-store', $limit: 50 },
        }) as Paginated<ProgramConfig<IStoreConfig>>;

        const fields = await Promise.all(result.data.map((config) => {
            if (config.data.service === 'api/v1/projects') {
                return this.api.programs
                    .get(config.data.query.program_id, { query: { $select: ['projectSchema'] } })
                    .then((result) => getFields([
                        ...projectFilters as Field[],

                        { name: 'metadata', type: 'object', fields: result.projectSchema },
                    ]))
                    .catch((e) => {
                        log.error(e);
                        return this.alerts.create({
                            label: `Project configuration: ${e}`,
                            level: 'warning',
                            dismissable: true,
                        });
                    });
            } else if (config.data.service === 'api/v1/form-submissions' ) {
                return this.api.programSurveys
                    .get(config.data.query.survey_id, { query: { $select: ['surveySchema'] } })
                    .then((result) =>  getFields(result.surveySchema))
                    .catch((e) => {
                        log.error(e);
                        return this.alerts.create({
                            label: `Survey configuration: ${e}. A survey may have been deleted and does not exist in the Dashboard Store.`,
                            level: 'warning',
                            dismissable: true,
                        });
                    });
            } else if (config.data.service === 'api/v1/programs-users-roles') {
                return getFields(programUserRoleFields);
            } else {
                return [];
            }
        })).catch((e) => {
            log.error(e);
            return this.alerts.create({
                label: `Dashboard Warning: ${e}`,
                level: 'warning',
                dismissable: true,
            });
        });

        this.stores = result.data.map((config, index) => {
            let flattenedFields = flattenFields(fields[index]);
            if ( config.data.query.$modify?.fieldSelection) {
                flattenedFields = flattenedFields.filter((field) => config.data.query.$modify.fieldSelection.includes(field.path));
            }

            return new SimpleStore().setup({
                name: config.label,
                service: this.api.app.service(config.data.service),
                query: config.data.query,
                mode: config.data.mode,
                idField: config.data.idField,
                id: config.id,
                fields: flattenedFields,
                geometryType: config.data.geometryType,
            });
        });

        this.storeOptions = this.getStoreOptions(this.stores);

    }

    getStoreOptions(stores: SimpleStore[]): Option[] {
        return stores.map((store) => ({
            value: store.id,
            label: store.name,
        }));
    }

    getStore(id: number | string): SimpleStore | void {
        id = ensureNumber(id);
        return this.stores.find((store) => store.id === id);
    }
}
