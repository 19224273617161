import { LogManager, autoinject } from 'aurelia-framework';
import { BrowserStorage } from './BrowserStorage';
import { Program } from '../../types/Program';
import { subscribe } from '../../util/decorators/subscribe';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { GeolocationService } from './Geolocation';
import { LocationResponse, ProgramUserRole } from '@wsb_dev/datafi-shared/lib/types';
import { Point } from 'geojson';
import { throttled } from '../../util/decorators/throttled';
import { ProgramActivity, RecentPrograms } from './RecentPrograms';
const log = LogManager.getLogger('dfp:programs');

/**
 * A simple state manager for active program
 */

@autoinject
@subscribe({
    events: [
        { eventEmitter: 'api.programs', event: 'patched', fn: 'onProgramPatched' },
        { eventEmitter: 'api.programs', event: 'updated', fn: 'onProgramPatched' },
        { eventEmitter: 'location', event: 'location', fn: 'onLocation' },
    ],
    attached: 'attachSubscribers',
    detached: 'unload',
})
export class ActiveProgram extends Program {

    id: number;
    public promise: Promise<any>;
    public locationTrackingEnabled: boolean;

    private activeProgramKey = 'activeProgramId';
    private initialized: boolean;

    constructor(
        private storage: BrowserStorage,
        private api: DatafiProAPI,
        private location: GeolocationService,
        private recentProgramActivity: RecentPrograms,
    ) {
        super({}, api.files);
        this.init();
    }

    async load(programId?: number | string, forceReload?: boolean): Promise<any> {
        log.info('Loading program: ', programId || 'active');

        this.init();

        if (this.promise && !forceReload) {
            return this.promise;
        }

        if (!forceReload && programId && this.id === programId) {
            log.info(`Program ${programId} is already active`);
            return Promise.resolve(this);
        }

        this.promise = this._load(programId)
            .catch((e) => log.warn(e));
        return this.promise;
    }

    private async _load(programId?: number | string): Promise<Program> {
        if (!programId) {
            programId = await this.storage.get(this.activeProgramKey)
                .then((res) => res.data)
                .catch((e) => {
                    log.debug(e);
                });
        }

        if (!programId) {
            return;
        }

        if (typeof programId === 'string') {
            programId = parseInt(programId, 10);
        }

        this.storage.create({
            id: this.activeProgramKey,
            data: programId,
        });

        return this.api.programs.get(programId, {
            query: {
                $eager: '[assetTypes, configs, surveys, roles.role]',
            },
        })
            .then((program) => {
                this.recentProgramActivity.manageActivity({ id: program.id, title: program.title, managementOrganizationId: program.managementOrganizationId } as ProgramActivity);
                Object.assign(this, new Program(program, this.api.files));
            })
            .catch((e) => undefined)
            .finally(() => this.handleLoaded());
    }

    handleLoaded() {
        // setup location tracking
        if (this.settings?.userLocation?.autoEnabled) {
            this.locationTrackingEnabled = true;
            this.location.enable();
        }

        // reset promise value
        this.promise = null;

    }

    /**
     * Subsrcriber handler whenever program gets patched
     */
    private onProgramPatched(program) {
        if (this.id === program.id) {
            this.load(this.id, true);
        }
    }

    // subsrciber handler whenever location gets triggered
    private onLocation(location: LocationResponse) {
        if (!this.locationTrackingEnabled) {
            return;
        }
        this.updateUserRole(location);
    }

    @throttled({
        interval: 10000,
    })
    updateUserRole(location: LocationResponse) {
        // if we are tracking location, find the role to patch and update it with the new lcoation
        const role = this.api.auth?.me?.programRoles?.find((role) => role.program_id === this.id);
        if (!role) {
            log.warn(`user does not have a role on this program: ${this.id}, ${this.title}`);
            return;
        }
        this.api.programUsersRoles.patch(role.id, {
            geom: {
                type: 'Point',
                coordinates: [
                    location.longitude,
                    location.latitude,
                ],
            } as Point,
        } as Partial<ProgramUserRole>).catch((e) => log.error('error updating role', e, role));
    }

    /**
     * Initializes the subscribers (safely)
     */
    init(): void {
        if (this.initialized) {
            return;
        }
        this.attachSubscribers();
    }

    /**
     * internal function to init the subscribers. Cannot be called without calling this.unload
     */
    private attachSubscribers() {
        if (this.initialized) {
            throw new Error('ActiveProgram: attachSubscribers cannot be called multiple times');
        }
        this.initialized = true;
    }

    /**
     * Safely clears the active program data and
     * removes subscribers
     */
    unload() {
        Object.keys(this).forEach((k) => {
            if (this.hasOwnProperty(k)) {
                this[k] = undefined;
            }
        });
        this.initialized = false;
    }
}
